'use client'

import { Navigation } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { Link } from '@/components'
import type { RelativePathType } from '@/types'

import { DesktopHeaderCta, MobileHeaderCta } from './header-cta'

const NavLink = ({
  pathname,
  path,
  children,
}: {
  pathname: string
  path: RelativePathType
  children: string
}) => {
  const isActive =
    path === '/'
      ? pathname === '/'
      : pathname.startsWith(path.replace(/\/$/, ''))
  return (
    <Navigation.Link
      active={isActive}
      aria-current={isActive ? 'page' : false}
      asChild
    >
      <Link
        sectionLabel="NavigationItem"
        trackingActionLabel={children}
        href={path}
      >
        {children}
      </Link>
    </Navigation.Link>
  )
}

export const Nav = ({
  labelMenus = { hub: 'The Hub' },
}: {
  labelMenus?: Record<string, string>
}) => {
  const pathname = usePathname()

  const showCta = useMemo(() => {
    const pathListRegex = /^\/?((sitemap)|(support)|(legal)|(download))\//
    return !pathListRegex.test(pathname)
  }, [pathname])

  return (
    <Navigation.Root aria-label="Main navigation" className="header">
      <Navigation.List
        className="main-navlist"
        hamburger-aria-label-opened="Close main navigation menu"
        hamburger-aria-label-closed="Open main navigation menu"
      >
        {showCta && (
          <Navigation.MenuItem className={cn('max-lg:tw-hidden')}>
            <DesktopHeaderCta />
          </Navigation.MenuItem>
        )}
        <Navigation.MenuItem className="lg:tw-hidden">
          <NavLink pathname={pathname} path="/">
            Home
          </NavLink>
        </Navigation.MenuItem>
        <Navigation.MenuItem>
          <Navigation.Trigger>Accounts</Navigation.Trigger>
          <Navigation.Content>
            <Navigation.SubList>
              <Navigation.MenuItem>
                <NavLink pathname={pathname} path="/product/chase-account/">
                  Current account
                </NavLink>
              </Navigation.MenuItem>
              <Navigation.MenuItem>
                <NavLink
                  pathname={pathname}
                  path="/product/chase-saver-account/"
                >
                  Saver account
                </NavLink>
              </Navigation.MenuItem>
            </Navigation.SubList>
          </Navigation.Content>
        </Navigation.MenuItem>
        <Navigation.MenuItem>
          <NavLink pathname={pathname} path="/our-story/">
            Our story
          </NavLink>
        </Navigation.MenuItem>
        <Navigation.MenuItem>
          <NavLink pathname={pathname} path="/hub/">
            {labelMenus.hub}
          </NavLink>
        </Navigation.MenuItem>
        <Navigation.MenuItem>
          <NavLink pathname={pathname} path="/support/">
            Support
          </NavLink>
        </Navigation.MenuItem>
        {showCta && (
          <Navigation.MenuItem className={cn('lg:tw-hidden')}>
            <MobileHeaderCta />
          </Navigation.MenuItem>
        )}
      </Navigation.List>
    </Navigation.Root>
  )
}
