'use client'

import { Button } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { usePathname } from 'next/navigation'
import type { ComponentPropsWithoutRef } from 'react'
import { useEffect, useMemo, useState } from 'react'

import { DownloadLink } from '@/components'
import { useHeaderCTAStore } from '@/lib/stores'

interface CtaLinkProps extends ComponentPropsWithoutRef<typeof Button> {
  className?: string
  'data-test-resolution': 'desktop' | 'mobile'
}

const CtaLink = ({
  className,
  'data-test-resolution': dataTestResolution,
  ...props
}: CtaLinkProps) => (
  <Button asChild noExternalIcon size="sm" className={cn(className)} {...props}>
    <DownloadLink
      sectionLabel="header"
      data-test-resolution={dataTestResolution}
    >
      Get the app
    </DownloadLink>
  </Button>
)

export const MobileHeaderCta = (
  props: Omit<ComponentPropsWithoutRef<typeof CtaLink>, 'data-test-resolution'>,
) => {
  return <CtaLink data-test-resolution="mobile" {...props} />
}

export const DesktopHeaderCta = (
  props: Omit<ComponentPropsWithoutRef<typeof CtaLink>, 'data-test-resolution'>,
) => {
  const pathname = usePathname()
  const [isVisible, setIsVisible] = useState(false)
  const { ctaRef, alwaysVisible } = useHeaderCTAStore()

  const client = useSplitStore((state) => state.clients.user)

  const isPermanantlyVisible = useMemo(() => {
    const pathListRegex = /^\/?((hub)|(media)|(updates-from-chase))\//
    return pathListRegex.test(pathname)
  }, [pathname])

  useEffect(() => {
    if (!isPermanantlyVisible && client) {
      if (alwaysVisible) {
        setIsVisible(true)
      } else {
        const checkIfHeroButtonIsVisible: IntersectionObserverCallback = (
          entries,
        ) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(false)
            } else {
              setIsVisible(true)
            }
          })
        }
        const options = {
          rootMargin: '10px',
          threshold: [1],
          root: null,
        }
        const observer = new IntersectionObserver(
          checkIfHeroButtonIsVisible,
          options,
        )

        if (ctaRef?.current) {
          observer.observe(ctaRef.current)
        } else {
          setIsVisible(false)
        }

        return () => {
          observer.disconnect()
        }
      }
    }
  }, [ctaRef, isPermanantlyVisible, client, alwaysVisible])

  const isHidden = !isPermanantlyVisible && !isVisible

  return (
    <CtaLink
      data-test-resolution="desktop"
      className={cn('tw-opacity-0 tw-transition-opacity tw-duration-200', {
        'tw-opacity-100': isPermanantlyVisible || isVisible,
      })}
      aria-hidden={isHidden}
      disabled={isHidden}
      tabIndex={isHidden ? -1 : 0}
      {...props}
    />
  )
}
